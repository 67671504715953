/*MAIN PAGE --> MAIN-SELECT*/


.cl2h_tab_wide_filter_item_wrap {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    height: 47px;
}

.main_registration_form_dob_row .cl2h_tab_wide_filter_item{
    width: 120px;
}

.main_registration_form_dob_row .cl2h_tab_wide_filter_item:nth-child(2) {
    width: 144px;
}

.cl2h_tab_wide_filter_item:not(.open) .like_select_head:hover {
    box-shadow: 0px 3px 3px rgba(28, 38, 119, 0.13);
}

.like_select_list.open {
    display: block;
}


.cl2h_tab_wide_filter_row2{
    width: 100%;
}

.cl2h_tab_wide_filter_row2_two{
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
}

.cl2h_tab_wide_filter_row2_dob{
    width: 410px;
    max-width: 100%;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
}

.cl2h_tab_wide_filter_item_wrap{
    height: 66px;
}

.cl2h_tab_wide_filter_row2_two .cl2h_tab_wide_filter_item_wrap{
    width: calc(50% - 4px);
}

.cl2h_tab_wide_filter_row2_big .cl2h_tab_wide_filter_item_wrap{
    height: 60px;
}

.cl2h_tab_wide_filter_row2_dob .cl2h_tab_wide_filter_item_wrap{
    width: 145px;
}

.cl2h_tab_wide_filter_row2_dob .cl2h_tab_wide_filter_item_wrap:nth-child(2){
    width: 100px;
}
.first_block .cl2h_tab_wide_filter_item{
    width: 357px;
}
.first_block .like_select_head{
    text-align: left;
}
.first_block .like_select_label{
    text-align: left;
}
.cl2h_tab_wide_filter_item{
    position: relative;
    width: 120px;
    height: 66px;
    background: #FFFFFF;
    border: 1px solid rgba(51, 51, 51, 0.3);
    box-sizing: border-box;
    border-radius: 5px;
    margin-right: 18px;
}

.like_select{
    opacity: 0;
    position: fixed;
    left: -999999px;
    top: -99999px;
}

.like_select_head{
    height: 63px;
    line-height: 63px;
    font-size: 24px;

    border-radius: 4px;
    padding: 0 30px 0 22px;
    color: rgba(51, 51, 51, 0.6);
    font-weight: 500;
    position: relative;
    z-index: 2;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.like_select_head.selected + .like_select_list {
    background-color: #EFF5FE;
}

.c3lh_search_filters_list_wrap .like_select_head{
    font-weight: 500;
    color: rgba(51, 51, 51, 0.6);
}

.cl2h_tab_wide_filter_row2_big .like_select_head{
    height: 60px;
    line-height: 60px;
    font-size: 18px;
}

.like_select_head:after{
    content: '';
    display: block;
    width: 10px;
    height: 6px;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    background-image: url(../img/selecet-arrow.svg);
    transition: .3s;
}

.open .like_select_head:after{
    transform: rotate(180deg);
}

.like_select_head.selected{
    color: #00317B;
    font-weight: 500;
    background-color: #EFF5FE;
}

.cl2h_tab_wide_filter_item.open{
    border-radius: 4px 4px 0 0;
    box-shadow: 0px 6px 12px rgba(47, 43, 99, 0.16);
    z-index: 4;
}

.like_select_list{
    display: none;
    background-color: #ffff;
    border-radius: 0 0 4px 4px;
    margin-top: -10px;
    padding: 20px 0 10px;
    position: relative;
    z-index: 6;
    max-height: 225px;
    overflow: auto;
}

.like_select_list::-webkit-scrollbar{
    width: 3px;
    background: #fff;
    border-radius: 22px;
}

.like_select_list::-webkit-scrollbar-thumb {
    background: #ADBAFF;
    border-radius: 9px;
}

.like_select_label{
    display: block;
    padding: 12px 12px 10px;
    color: rgba(51, 51, 51, 0.9);
    font-weight: 500;
    margin: 0 10px;
    border-radius: 4px;
    cursor: pointer;
}

.cl2h_tab_wide_filter_row2_big .like_select_label{
    font-size: 18px;
    padding: 20px 5px 18px;
}

.like_select:checked + .like_select_label{
    background-color: #E3ECFC;
}

.like_select_label:hover{
    background-color: #E3ECFC;
}

@media screen and (max-width: 1799px){

    .cl2h_tab_wide_filter_row2_dob .cl2h_tab_wide_filter_item_wrap{
        width: 125px;
    }

    .cl2h_tab_wide_filter_row2_dob .cl2h_tab_wide_filter_item_wrap:nth-child(2){
        width: 75px;
    }
}

@media screen and (max-width: 1699px){
    .cl2h_tab_wide_filter_row2_dob .cl2h_tab_wide_filter_item_wrap{
        width: 110px;
    }

    .cl2h_tab_wide_filter_row2_dob .cl2h_tab_wide_filter_item_wrap:nth-child(2){
        width: 70px;
    }
}

@media screen and (max-width: 1649px){
    .cl2h_tab_wide_filter .like_select_head{
        padding-left: 5px;
        font-size: 14px;
    }

    .cl2h_tab_wide_filter .like_select_label{
        margin: 0 5px;
        padding-left: 5px;
        padding-right: 5px;
    }
}

@media screen and (max-width: 1599px){

}

@media screen and (max-width: 1499px){
    .cl2h_tab_wide_filter_row2_dob .cl2h_tab_wide_filter_item_wrap{
        width: 87px;
    }

    .cl2h_tab_wide_filter_row2_dob .cl2h_tab_wide_filter_item_wrap:nth-child(2){
        width: 60px;
    }

    .cl2h_tab_wide_filter_row2_big .like_select_head{
        height: 42px;
        line-height: 42px;
        font-size: 14px;
        padding-left: 10px;
    }
}

@media screen and (max-width: 999px){
    .c3_profile_edit_block_item > label{
        font-size: 12px;
    }
}

@media screen and (max-width: 767px){
    .c3_profile_edit_block_list{
        display: block;
    }

    .c3_profile_edit_block_item{
        margin-bottom: 16px;
    }

    .c3_profile_edit_big_block{
        display: block;
    }

    .c3_profile_edit_block_title{
        text-align: center;
        margin-bottom: 10px;
    }

    .c3_profile_edit_block_subtitle{
        text-align: center;
        font-size: 16px;
        margin-bottom: 10px;
    }

    .c3_profile_edit_big_block_right{
        position: relative;
    }

    .c3_profile_edit_big_block_head .c3_profile_edit_block_title{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        text-align: center;
        top: -35px;
        margin-top: 24px;
    }

    .c3_profile_edit_big_block{
        padding-top: 40px;
    }

    .c3_profile_edit_big_block_id{
        display: none;
    }
}

@media screen and (max-width: 599px){

    .cl2h_tab_wide_filter_row2_dob .cl2h_tab_wide_filter_item_wrap{
        min-width: 154px;
        width: 100%;
        height: 30px;
    }

    .cl2h_tab_wide_filter_row2_dob .cl2h_tab_wide_filter_item_wrap:nth-child(2){
        min-width: 74px;
        width: 100%;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .cl2h_tab_wide_filter_row2_big .like_select_head{
        height: 30px;
        line-height: 30px;
        font-size: 12px;
    }

    .cl2h_tab_wide_filter_row2_dob{
        flex-wrap: wrap;
    }

    .like_select_head{
        height: 30px;
        line-height: 30px;
        font-size: 12px;
    }

    .c3lh_search_filter_item_hobbies_title{
        height: 30px;
        line-height: 30px;
    }

    .c3lh_search_filter_item label {

    }

    .like_select_label,
    .cl2h_tab_wide_filter_row2_big .like_select_label{
        font-size: 12px;
        padding-top: 9px;
        padding-bottom: 7px;
    }

    .cl2h_tab_wide_filter_item_wrap{
        height: 30px;
        justify-content: space-between;
    }

    .like_select_list{
        max-height: 175px;
    }

    .cl2h_tab_wide_filter_item{
        height: 32px;
    }

    .first_block .cl2h_tab_wide_filter_item{
        width: 100%;
        margin-right: 0;
    }

    .cl2h_tab_wide_filter_item_wrap .cl2h_tab_wide_filter_item{
        width: calc((100% / 3) - 10px);
        margin: 0;
    }

    .fill_data_form_select_item .cl2h_tab_wide_filter_item{
        width: 100%;
    }

    .cl2h_tab_wide_filter_item_wrap .like_select_head{
        padding: 0 5px;
        text-align: left;
    }

    .like_select_head:after{
        right: 8px;
    }
}
